import React from "react"
import { graphql, navigate } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { PageSeo } from "../components/PageSeo"
import Layout from "../components/Layout"
import "./styles/offerings.scss"

const IndustriesSlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, banner, description, content, blurb, productsOffering } =
    markup.frontmatter

  const solutionofferings = data.allMarkdownRemark.edges.filter(item => {
    return productsOffering.includes(item.node.frontmatter.nameRef)
  })

  return (
    <Layout>
      <section className="IndustriesSlug">
        <div className="container text-center py-5 animate__animated animate__fadeIn">
          <div className="row">
            <h2 className="section_heading">{name}</h2>
            <div className="col-12 col-lg-8 mx-auto">
              <ReactMarkdown children={description} />
            </div>
            <div className="col-12 col-lg-8 mx-auto animate__animated animate__fadeInUp">
              <ReactMarkdown children={content} />
            </div>
          </div>
        </div>

        <div className="banner animate__animated">
          <GatsbyImage
            image={banner.image.childImageSharp.gatsbyImageData}
            alt={name}
            className="bannerImage py-3 py-lg-5"
          />
          {/* <div className="container text-white text-start d-none d-lg-block">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="text-white animate__animated animate__fadeIn">{banner.heading}</h2>
              </div>
            </div>
          </div> */}
        </div>
        <QuestionAnswer blurb={blurb} />
        <Solutionofferings solutionofferings={solutionofferings} />
      </section>
    </Layout>
  )
}
IndustriesSlug.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const QuestionAnswer = ({ blurb }) => {
  return (
    <div className="py-3 mb-3">
      <div className="container">
        {blurb.length &&
          blurb.map((item, index) => (
            <div className="row mt-4">
              <div className="col-12 col-lg-7 mx-auto">
                <h5 className="text-center">{item.question}</h5>
                <p className="text-center">{item.answer}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
QuestionAnswer.propTypes = {
  blurb: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
}

const Solutionofferings = ({ solutionofferings }) => {
  return (
    <div className="py-3 mb-5">
      <div className="container">
        <h4 className="text-center">Solution Offerings</h4>
        <ul className="nav nav-pills mb-3 tab-horizontal-custom  d-none d-lg-flex">
          {solutionofferings.length &&
            solutionofferings.map(({ node }, index) => (
              <li className="nav-item" key={`solutionofferings_nav_${index}`}>
                <button
                  className={`nav-link ${index === 0 ? "active" : ""}`}
                  data-bs-toggle="tab"
                  data-bs-target={`#solutionofferings-content_${index}`}
                >
                  <b> {node.frontmatter.name} </b>
                </button>
              </li>
            ))}
        </ul>
        <div>
          {solutionofferings.length &&
            solutionofferings.map(({ node }, index) => (
              <div
                className="tab-content mb-3 mb-lg-0"
                key={`solutionofferings_tab_${index}`}
              >
                <div
                  className={`tab-pane bg-white border rounded ${
                    index === 0 ? "active" : ""
                  } p-3`}
                  id={`solutionofferings-content_${index}`}
                >
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <GatsbyImage
                        image={
                          node.frontmatter.logo.childImageSharp.gatsbyImageData
                        }
                        alt={node.frontmatter.name}
                        className="mb-3"
                      />

                      <ReactMarkdown
                        children={node.frontmatter.content}
                      ></ReactMarkdown>

                      <div className="mb-3 mb-lg-0">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            navigate(node.frontmatter.url)
                          }}
                          title={`Read More on ${node.frontmatter.name}`}
                        >
                          {" "}
                          Read More{" "}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 p-1">
                      <GatsbyImage
                        image={
                          node.frontmatter.image.childImageSharp.gatsbyImageData
                        }
                        alt={node.frontmatter.name}
                        className="rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
export const IndustryQuery = graphql`
  query IndustryQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoContent {
          description
          keywords
          title
        }
        name
        productsOffering
        image {
          childImageSharp {
            gatsbyImageData(width: 50, quality: 100, layout: CONSTRAINED)
          }
        }
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
        }
        content
        description
        blurb {
          question
          answer
        }
        benefits {
          shorttitle
          description
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "products-slug" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            nameRef
            content
            link
            url
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            logo {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 40, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
export default IndustriesSlug

export const Head = ({ data }) => (
  <PageSeo
    title={
      data.markdownRemark.frontmatter.seoContent.title ||
      data.markdownRemark.frontmatter.name
    }
    description={
      data.markdownRemark.frontmatter.seoContent.description ||
      data.markdownRemark.frontmatter.name
    }
    keywords={
      data.markdownRemark.frontmatter.seoContent.keywords ||
      data.markdownRemark.frontmatter.name
    }
  />
)
